import { FC } from 'react';
// Interfaces
import { IconSvgProps } from '@utmb/types/icons';

const Calendar: FC<IconSvgProps> = ({ color, clss }) => {
    return (
        <svg width="20" height="20" viewBox="0 0 43.45 43.43" className={clss}>
            <g>
                <path
                    fill={color}
                    d="m37.91 3.36-1.98 1.86h5.65v7.42H1.86V5.22h4.86L8.7 3.36H0v37.48l1.86-1.76V14.51h39.72v27.05H1.95L0 43.41v.02h43.45V3.36h-5.54z"
                />
                <path
                    fill={color}
                    d="M29.85 3.36h-13.1l-1.98 1.86h13.11l1.97-1.86zM31.84 0h1.86v6.05h-1.86zM10.48.02h1.86v6.05h-1.86zM6.62 18.92h6.34v1.95H6.62zM18.67 18.92h6.34v1.95h-6.34zM30.73 18.92h6.34v1.95h-6.34zM6.62 34.65h6.34v1.95H6.62zM18.67 34.65h6.34v1.95h-6.34zM30.73 34.65h6.34v1.95h-6.34zM6.62 26.79h6.34v1.95H6.62zM18.67 26.79h6.34v1.95h-6.34zM30.73 26.79h6.34v1.95h-6.34z"
                />
            </g>
        </svg>
    );
};

export default Calendar;
